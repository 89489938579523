/* eslint-disable no-return-assign */
import React from 'react'
import PropTypes from 'prop-types'

const Offerings = ({ offerings }) => (
  <div className='flex flex-wrap justify-between items-start'>
    {offerings.map(item => (
      <div key={item.title} className='w-full md:w-1/2 flex flex-col items-center justify-center p-4 hover:bg-white ' onClick={() => global.location.href = item.link}>
        <div className='w-14 h-14 rounded-full mr-4 bg-gray-300' alt='' />
        <h2 className='my-4 font-medium text-xl font-primary'>
          {item.title}
        </h2>
        <p className='leading-normal mb-4 '>
          {item.text}
        </p>
      </div>
    ))}
  </div>
)

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default Offerings
