/* eslint-disable jsx-quotes */
import React from "react";
import Helmet from "react-helmet";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Testimonials from "../Testimonials";
import Page from "../Page";
import PostCard from "../PostCard";
import PropTypes from "prop-types";
import "./style.css";
import Offerings from "../Offerings";
import Appearance from "../Appearance";
import { SubscriptionForm } from "../forms/SubscriptionForm";

const HomePageTemplate = ({
  title,
  heading,
  description,
  hero_title,
  hero_description,
  hero_cta,
  cta_title,
  cta_description,
  author_title,
  author_name,
  author_bio,
  project_title,
  project_description,
  meta_title,
  meta_description,
  offerings,
  testimonials,
}) => (
  <Page isHome>
    <Helmet>
      <title>{meta_title}</title>
      <meta name="description" content={meta_description} />
    </Helmet>
    <section className="font-sans hero">
      <div className="container m-auto flex flex-col md:flex-row">
        <div className="w-full lg:w-1/3 text-white">
          <h2 className="my-2 px-2 text-4xl font-thin font-primary uppercase text-center">
            Book I:
          </h2>
          <h2 className="my-2 px-2 text-4xl font-thin font-primary uppercase text-center">
            JEFFREY EPSTEIN
          </h2>
          <img
            className="w-full m-auto max-w-md md:pt-8 xl:pt-0"
            alt="Book Cover"
            src="https://m.media-amazon.com/images/I/51QWGfexbFL._SY346_.jpg"
          />
          <p className="leading-normal mt-4 mb-4">{hero_description}</p>

          <div className="sm:max-w-md md:max-w-sm my-2 sm:flex items-center">
            <div className="items-center">
              <button
                className="bg-primary hover:bg-black text-white text-xl font-primary py-2 px-4 border border-transparent hover:border-black m-1"
                onClick={() =>
                  (global.location.href =
                    "https://www.amazon.com/Godfathers-Sex-Abuse-Book-Jeffrey/dp/173399582X/ref=sr_1_1?keywords=godfather+of+sex+abuse&qid=1577659915&sr=8-1")
                }
              >
                Buy Now
              </button>
              <button className="bg-transparent hover:bg-black text-black text-xl font-primary hover:text-white py-2 px-4 border border-black hover:border-transparent m-1">
                <AnchorLink offset={() => 100} href="#series">
                  About The Series
                </AnchorLink>
              </button>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3" />
        <div className="w-full lg:w-1/3 text-white">
          <h2 className="my-2 px-2 text-4xl font-thin font-primary uppercase text-center">
            Book II:
          </h2>
          <h2 className="my-2 px-2 text-4xl font-thin font-primary uppercase text-center">
            Harvey Weinstein & Bill Cosby
          </h2>
          <img
            className="w-full m-auto max-w-md md:pt-8 xl:pt-0"
            alt="Book Cover"
            src="https://m.media-amazon.com/images/I/51iSWUs+X-L.jpg"
          />
          <p className="leading-normal mt-4 mb-4">
            Following her first groundbreaking work, The Godfathers of Sex
            Abuse, Book I: Jeffrey Epstein, Professor Deana Pollard Sacks now
            takes on two other monstrous sex abusers: Harvey Weinstein and Bill
            Cosby. Sacks not only presents their dark stories of fraud,
            manipulation, and coercion, but she broadens the perspective to
            reveal the other types of abuse commonly aimed at women, simply
            because of their gender.
          </p>

          <div className="sm:max-w-md md:max-w-sm my-2 sm:flex items-center">
            <div className="items-center">
              <button
                className="bg-primary hover:bg-black text-white text-xl font-primary py-2 px-4 border border-transparent hover:border-black m-1"
                onClick={() =>
                  (global.location.href =
                    "https://www.amazon.com/Godfathers-Sex-Abuse-Book-Jeffrey/dp/173399582X/ref=sr_1_1?keywords=godfather+of+sex+abuse&qid=1577659915&sr=8-1")
                }
              >
                Buy Now
              </button>
              <button className="bg-transparent hover:bg-black text-black text-xl font-primary hover:text-white py-2 px-4 border border-black hover:border-transparent m-1">
                <AnchorLink offset={() => 100} href="#series">
                  About The Series
                </AnchorLink>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="antialiased font-sans w-full bg-black text-left text-white py-8">
      <div className="container m-auto max-w-6xl pt-4 pb-4 text-center">
        <h3 className="text-2xl font-primary text-primary">
          Get A Signed Copy!
        </h3>
        <h1 className="font-primary text-xl">
          For a signed copy of Book I: Jeffrey Epstein to be sent to an address
          in the continental US, send a check for $25 to Deana at the Sacks Law
          Firm, 2323 S. Shepherd Drive, Suite 825, Houston, Texas 77019. Please
          include your preferred name or nickname.
        </h1>

        <div className="inline-flex w-full">
          <a className="mx-auto" href="mailto:DeanaPollardSacks@gmail.com">
            <button className="bg-primary hover:bg-black text-white text-xl font-primary py-2 px-4 border border-transparent hover:border-black m-auto">
              Contact
            </button>
          </a>
        </div>
      </div>
    </section>
    <section className="antialiased font-sans w-full bg-gray-300 text-left text-black py-8">
      <div className="container m-auto flex flex-col md:flex-row max-w-6xl p-6">
        <div className="flex-col lg:w-1/2 text-center">
          <h3 className="text-2xl font-primary text-primary">{cta_title}</h3>
          <p className="text-xl">{cta_description}</p>
        </div>
        <SubscriptionForm />
      </div>
    </section>
    <section className="antialiased font-sans w-full bg-black text-left text-white py-8">
      <div className="container m-auto max-w-6xl pt-4 pb-4 text-center">
        <h3 className="text-2xl font-primary text-primary">Latest News!</h3>
        <h1 className="font-primary text-xl">
          `The #MeToo Network is being created, to connect victims (female and
          male) to lawyers, researchers, and concerned citizens, and to pool
          resources to fight back against sexual predators and our failing
          system. It will be http://MeTooNetwork.com . #TimesUp.
          #godfathersofsexabuse`
        </h1>
      </div>
    </section>
    <section className="my-8 font-sans container m-auto" id="appearance">
      <div className="w-full flex flex-col justify-center items-start px-6 py-0 md:py-8 md:px-8 lg:items-center">
        <h1 className="mt-2 mb-4 font-medium text-3xl font-primary text-center">
          Appeared On
        </h1>
      </div>
      <Appearance />
    </section>
    <section className="my-8 font-sans container m-auto" id="praise">
      <div className="w-full flex flex-col justify-center items-start px-6 py-0 md:py-8 md:px-8 lg:items-center">
        <h1 className="mt-2 mb-4 font-medium text-3xl font-primary text-center">
          "Praise for The Godfathers of Sex Abuse"
        </h1>
      </div>
      <Testimonials testimonials={testimonials} />
    </section>
    <div className="bg-gray-200 m-2  p-4" id="series">
      <section className="my-8 font-sans container m-auto ">
        <div className="text-center">
          <h1 className="my-4 font-medium font-primary text-4xl text-primary text-center">
            {heading}
          </h1>
          <p className="leading-normal mb-4 m-auto">{description}</p>
        </div>
        <div className="flex flex-col sm:flex-row flex-wrap my-8">
          <PostCard isChapter />
        </div>
      </section>
    </div>
    <div className="bg-black text-white p-4" id="author">
      <section className="font-sans container m-auto flex flex-col py-8 text-center px-6 bg-black text-white">
        <h1 className="text-sm uppercase font-secondary">{author_title}</h1>
        <h1 className="my-4 m-auto text-3xl md:text-2xl lg:text-3xl font-primary font-medium">
          {author_name}
        </h1>
        <p className="mt-1 lg:mt-4 m-auto leading-loose mb-6 text-left">
          {author_bio}
        </p>
      </section>
    </div>
    <div className="bg-gray-300 text-white p-4" id="other">
      <section className="font-sans container m-auto flex flex-col py-8 text-center px-6 text-gray-900">
        <h1
          className="my-4 m-auto text-3xl md:text-2xl lg:text-3xl font-primary font-medium"
          alt-h1="Other Projects"
        >
          {project_title}
        </h1>
        <Offerings offerings={offerings} />
      </section>
    </div>
    <section className="bg-white text-black p-4" id="blog">
      <div className="container m-auto flex flex-wrap items-center justify-center">
        <h1 className="my-4 font-medium font-primary text-4xl text-primary">
          The Blog
        </h1>
      </div>
      <div className="container m-auto flex flex-wrap items-center justify-start">
        <PostCard />
      </div>
    </section>
  </Page>
);

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,
};

export default HomePageTemplate;
