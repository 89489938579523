import React from 'react'
import PropTypes from 'prop-types'

const Appearance = ({ testimonials = [{author: "CW39 Morning Dose", pic: "https://cw39.com/wp-content/uploads/sites/10/2020/02/morning-dose-logo-web-resize.png"}] }) => (
  <div className='flex flex-wrap sm:justify-center justify-between p-2 md:p-8 md:items-start'>
    {testimonials.map((testimonial, id) => (
      <div key={id} className='sm:w-full md:w-1/2 xl:w-1/3'>
        <article className='flex items-center p-4 '>
          <div>
            <div className='md:flex bg-black rounded-lg p-6'>
              <img
                className='mx-auto md:mx-0 md:mr-6'
                src={testimonial.pic}
                alt={testimonial.author}
              />
              <div className='text-center md:text-left'>
                <h2 className='text-lg text-white'>{testimonial.author}</h2>
              </div>
            </div>
          </div>
        </article>
      </div>
    ))}
  </div>
)

Appearance.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      pic: PropTypes.string,
    })
  ),
}

export default Appearance
