import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HomePageTemplate from '../components/HomePageTemplate'

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <HomePageTemplate
      title={frontmatter.title}
      meta_title={frontmatter.meta_title}
      meta_description={frontmatter.meta_description}
      heading={frontmatter.heading}
      description={frontmatter.description}
      hero_title={frontmatter.hero_title}
      hero_description={frontmatter.hero_description}
      hero_cta={frontmatter.hero_cta}
      cta_title={frontmatter.cta_title}
      cta_description={frontmatter.cta_description}
      author_title={frontmatter.author_title}
      author_name={frontmatter.author_name}
      author_bio={frontmatter.author_bio}
      project_title={frontmatter.project_title}
      project_description={frontmatter.project_description}
      testimonials={frontmatter.testimonials}
      offerings={frontmatter.offerings}
    />
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        description
        meta_title
        meta_description
        hero_title
        hero_description
        hero_cta
        cta_title
        cta_description
        author_title
        author_name
        author_bio
        project_title
        project_description
        offerings {
          title
          link
          text
        }
        testimonials {
          author
          quote
          biopic
        }
      }
    }
  }
`
