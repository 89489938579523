/* eslint-disable no-undef */
import React from 'react'
import { Formik, Field } from 'formik'
import { navigate } from 'gatsby-link'
import validationSchema from './validationSchema'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const SubscriptionForm = () => {
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        fetch('/?no-cache=1', {
          //eslint-disable-line
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'subscription',
            ...values,
          }),
        })
          .then(() => {
            navigate('/')
            setSubmitting(false)
          })
          .catch(error => {
            console.log(error)
            alert("Error: Please Try Again!"); //eslint-disable-line
            setSubmitting(false)
          })
      }}
      render={({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
      }) => (
        <form
          name='subscription'
          className='mt-4 sm:flex lg:w-1/2 justify-end'
          onSubmit={handleSubmit}
          onReset={handleReset}
          data-netlify='true'
          data-netlify-honeypot='bot-field'
        >
          <Field
            className='block w-full focus:outline-0 bg-white py-3 px-6 mb-2 sm:mb-0'
            type='email'
            placeholder='Enter your email'
            name='email'
            id='email'
          />
          <button
            type='submit'
            disabled={isSubmitting}
            className='uppercase text-sm text-white focus:outline-0 w-full sm:w-auto bg-black hover:bg-gray-900 focus:bg-gray-300 tracking-wide px-6 font-primary'>
            Subscribe
          </button>
          {touched.email && errors.email && (
            <small className='has-text-danger'>{errors.email}</small>
          )}
        </form>
      )}
    />
  )
}

export { SubscriptionForm }
