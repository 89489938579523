import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const PostCardComponent = ({ posts, isChapter }) => {
  return (
    <div className='container'>

      <div className='flex flex-wrap sm:justify-center justify-between p-2 md:p-8 md:items-start'>
        {isChapter ? posts
          .filter(post => post.node.frontmatter.articleType === 'Chapter')
          .map(({ node: post }) => (
            <div key={post.id} className='py-1 px-2'>
              <div className='max-w-sm rounded overflow-hidden shadow-lg hover:shadow-2xl bg-white'>
                <div className='px-6 py-4'>
                  <Link className='font-bold text-2xl mb-2 font-primary ' to={post.fields.slug}>{post.frontmatter.title}</Link>
                  <p className='text-gray-700 text-base'>
                    {post.excerpt}
                    <Link to={post.fields.slug} className='text-gray-700 text-base'>Keep Reading →</Link>
                  </p>
                </div>
              </div>
            </div>
          )) : posts
          .filter(post => post.node.frontmatter.templateKey === 'article-page' && post.node.frontmatter.articleType !== 'Chapter')
          .map(({ node: post }) => (
            <div key={post.id} className='py-1 px-2'>
              <div className='max-w-sm rounded overflow-hidden shadow-lg hover:shadow-2xl bg-white'>
                <img className='w-full' src={post.frontmatter.cover} alt='Blog Image' />
                <div className='px-6 py-4'>
                  <Link className='font-bold text-xl mb-2 font-primary ' to={post.fields.slug}>{post.frontmatter.title}</Link>
                  <p className='text-gray-700 text-base'>
                    {post.excerpt}
                    <Link to={post.fields.slug} className='text-gray-700 text-base'>Keep Reading →</Link>
                  </p>
                </div>
                <div className='px-6 py-4'>
                  {post.frontmatter.tags.map(tag =>
                    <span key={tag} className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2'>{tag}</span>)}
                </div>
              </div>
            </div>
          ))}
        {}
      </div>
    </div>
  )
}

const PostCard = ({ isChapter }) => (
  <StaticQuery
    query={graphql`
      query PostCardQuery {
        allMarkdownRemark(
          limit: 1000,
          sort: { order: DESC, fields: [frontmatter___date] },
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              html
              id
              fields { slug }
              frontmatter {
                title
                templateKey
                articleType
                cover
                date(formatString: "MMMM DD, YYYY")
                tags
              }
            }
          }
        }
      }
    `}
    render={(
      data
    ) => (
      <PostCardComponent isChapter={isChapter} posts={data.allMarkdownRemark.edges} />
    )}
  />
)

export default PostCard
