import React from 'react'
import PropTypes from 'prop-types'

const Testimonials = ({ testimonials }) => (
  <div className='flex flex-wrap sm:justify-center justify-between p-2 md:p-8 md:items-start'>
    {testimonials.map((testimonial, id) => (
      <div key={id} className='sm:w-full md:w-1/2 xl:w-1/3'>
        <article className='flex items-center p-4 '>
          <div>
            <p className='text-gray-700 leading-normal text-xl'>
              {testimonial.quote}
            </p>
            <div className='sm:border-r md:border-b md:border-r-0 p-2' />
            <div className='md:flex bg-white rounded-lg p-6'>
              <img
                className='h-16 w-16 md:h-24 md:w-24 rounded-full mx-auto md:mx-0 md:mr-6'
                src={testimonial.biopic}
                alt={testimonial.author}
              />
              <div className='text-center md:text-left'>
                <h2 className='text-lg'>{testimonial.author}</h2>
              </div>
            </div>
          </div>
        </article>
      </div>
    ))}
  </div>
)

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
      biopic: PropTypes.string,
    })
  ),
}

export default Testimonials
